import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { TextValuePair } from "@bb-core/entity";
import { map, Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { AccountResetSettingsDto } from "./account-reset-settings.dto";
import { AddressUpdateDto } from "./address-update.dto";
import { AgreeLegalDto } from "./agree-legal.dto";
import { AvvSettingsDto } from "./avv-settings.dto";
import { ExternalLoginDto } from "./external-login.dto";
import { MailNotificationSettingsDto } from "./mail-notification-settings.dto";
import { PaymentInformationResponseDto } from "./payment-information-response.dto";
import { PaymentInformationDto } from "./payment-information.dto";
import { ServicePinSettingsDto } from "./service-pin-settings.dto";
import { StripeMandateDto } from "./stripe-mandate.dto";
import { UserProfileDto } from "./user-profile.dto";

@Injectable({ providedIn: "root" })
export class AccountRepository {
    private readonly httpClient = inject(HttpClient);
    private readonly baseUrl = `${environment.baseUrl}/account`;

    getMailNotificationSettings(): Observable<MailNotificationSettingsDto> {
        return this.httpClient.get<MailNotificationSettingsDto>(`${this.baseUrl}/mail-notification-settings`);
    }

    setEmailNotificationSettings(settings: MailNotificationSettingsDto): Observable<UserProfileDto> {
        return this.patchProfile(settings);
    }

    getDetails(): Observable<UserProfileDto> {
        return this.httpClient.get<UserProfileDto>(`${this.baseUrl}/details`);
    }

    patchProfile(user: Partial<UserProfileDto>): Observable<UserProfileDto> {
        return this.httpClient.patch<UserProfileDto>(`${this.baseUrl}/profile`, user);
    }

    getExternalLogins(): Observable<ExternalLoginDto[]> {
        return this.httpClient.get<ExternalLoginDto[]>(`${this.baseUrl}/external-logins`);
    }

    deleteExternalLogin(externalLogin: ExternalLoginDto): Observable<void> {
        return this.httpClient.delete<void>(`${this.baseUrl}/externalLogins/${externalLogin.Id}`);
    }

    getServicePinSettings(): Observable<ServicePinSettingsDto> {
        return this.httpClient.get<ServicePinSettingsDto>(`${this.baseUrl}/service-pin-settings`);
    }

    getAvvSettings(): Observable<AvvSettingsDto> {
        return this.httpClient.get<AvvSettingsDto>(`${this.baseUrl}/avv-settings`);
    }

    resetAccount(settings: AccountResetSettingsDto): Observable<void> {
        return this.httpClient.post<void>(`${this.baseUrl}/reset`, settings);
    }

    deleteAccount(confirmDeletion: boolean): Observable<void> {
        return this.httpClient.post<void>(`${this.baseUrl}/delete`, { ConfirmDeletion: confirmDeletion });
    }

    getCountries(): Observable<TextValuePair<string>[]> {
        return this.httpClient.get<TextValuePair<string>[]>(`${this.baseUrl}/countries`);
    }

    public updateInvoiceAddress(invoiceAddress: AddressUpdateDto): Observable<AddressUpdateDto> {
        return this.httpClient.post<AddressUpdateDto>(`${this.baseUrl}/invoice-address`, invoiceAddress);
    }

    public createStripeSetupIntent(): Observable<{ ClientSecret: string }> {
        return this.httpClient.get<{ ClientSecret: string }>(`${this.baseUrl}/create-stripe-setup-intent`);
    }

    getMandateUrlForSetupIntent(setupIntentId: string): Observable<string | undefined> {
        return this.httpClient
            .get<StripeMandateDto>(`${this.baseUrl}/get-stripe-mandate-for-setup-intent/${setupIntentId}`)
            .pipe(map(({ payment_method_details }) => payment_method_details[payment_method_details.type]?.url));
    }

    public updatePaymentInformation(
        paymentInformation: PaymentInformationDto,
    ): Observable<PaymentInformationResponseDto> {
        return this.httpClient.post<PaymentInformationResponseDto>(
            `${this.baseUrl}/payment-information`,
            paymentInformation,
        );
    }

    public agreeLegal(accepted: AgreeLegalDto): Observable<void> {
        return this.httpClient.post<void>(`${this.baseUrl}/agree-legal`, accepted);
    }

    public getDpaAsFile(): Observable<Blob> {
        return this.httpClient.get(`${this.baseUrl}/dpa`, {
            responseType: "arraybuffer",
            headers: { accept: "application/pdf" },
            params: { generated: "true" },
        }).pipe(map(data => new Blob([data], { type: "application/pdf" })));
    }
}
