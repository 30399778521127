<div class="loading-indicator fill-primary" *ngIf="(showNavigation | async) && (busyService.isBusy$ | async)">
    <mat-icon fontIcon="fa-sync-alt"></mat-icon>
</div>
<div fxLayout="column" fxFlexFill>
    <div fxLayout="row" fxFlex="none">
        <mat-toolbar color="primary" fxFlex [hidden]="!(showNavigation | async)">
            <mat-toolbar-row fxLayout="row" fxLayoutGap="8px">
                <button
                    type="button"
                    aria-label="Toggle drawer"
                    mat-icon-button
                    (click)="drawer.toggle()"
                    *ngIf="isHandset$ | async"
                >
                    <i class="fal fa-fw fa-bars"></i>
                </button>
                <!-- fix me after full migration to Angular2-->
                <div
                    (click)="navigate('/')"
                    class="svg_logo"
                    *ngIf="!(isHandset$ | async)"
                    [ngClass]="expandedSidebar ? 'expanded' : ''"
                ></div>
                <ng-container *ngIf="!(isHandset$ | async)">
                    <div fxLayout="row">
                        <ng-container *ngFor="let title of titleHistory; let last = last">
                            <div [fxHide.lt-md]="!last">
                                <a *ngIf="!last" [routerLink]="urlHistory[title]">{{ title | translate }}</a>
                                <span *ngIf="last">{{ title | translate }}</span>
                                <span *ngIf="!last" style="margin: 0 8px">/</span>
                            </div>
                        </ng-container>
                    </div>
                    <div fxFlex="15" *ngIf="filterOptions.length">
                        <mat-divider style="height: 24px" [vertical]="true"></mat-divider>
                        <mat-select placeholder="Alle Bestellungen" (change)="onFilterChange.emit($event)">
                            <span matSubheader>Filter</span>
                            <mat-option *ngFor="let opt of filterOptions" [value]="opt.val">{{ opt.text }}</mat-option>
                        </mat-select>
                    </div>
                </ng-container>
                <ng-container *ngIf="showNewButton">
                    <mat-divider style="height: 24px" [vertical]="true"></mat-divider>
                    <button mat-button mat-icon-button type="button" matTooltip="Neu" (click)="onClickNew.emit(null)">
                        <i class="fal fa-fw fa-plus"></i>
                    </button>
                </ng-container>

                <span fxFlex *ngIf="!(isHandset$ | async)"></span>

                <ng-container *ngIf="isDevEnvironment && !(isHandset$ | async)">
                    <button type="button" mat-button color="warn" class="dev-sys-warning">
                        Test- oder Entwicklungssystem
                    </button>
                </ng-container>

                <ng-container *ngIf="isImpersonated && !(isHandset$ | async)">
                    <button type="button" mat-button class="dev-sys-note">
                        Impersonated on Instance: '{{ instanceName }}'
                    </button>
                </ng-container>

                <mat-form-field
                    floatLabel="never"
                    [fxFlex]="(isHandset$ | async) ? 'grow' : 18"
                    class="search-field nav-search"
                >
                    <mat-label>Suche...</mat-label>
                    <input #inp matInput (keyup.enter)="doSearch(inp.value)" />
                    <mat-icon matSuffix fontIcon="fa-search" (click)="doSearch(inp.value)"></mat-icon>
                </mat-form-field>

                <button
                    type="button"
                    mat-icon-button
                    *ngIf="isImpersonated"
                    (click)="navigate('/administration/UserAccount/ReturnToAdmin')"
                >
                    <i class="fas fa-certificate"></i>
                </button>

                <button
                    mat-button
                    type="button"
                    aria-label="Benutzerkonto aufrufen"
                    [ngClass]="{ 'mat-icon-button': (isHandset$ | async), 'mat-button': !(isHandset$ | async) }"
                    [matMenuTriggerFor]="userMenu"
                >
                    <mat-icon fontIcon="fa-user"></mat-icon>
                    <span style="margin-left: 8px" *ngIf="!(isHandset$ | async)">
                        {{ userName$ | async }}
                        <i class="fas fa-fw fa-caret-down"></i>
                    </span>
                </button>

                <mat-menu #userMenu="matMenu">
                    <button
                        mat-menu-item
                        *ngIf="userRoles && userRoles['einstellungen - benutzerkonto']"
                        routerLink="/account/settings"
                    >
                        <mat-icon fontIcon="fa-user"></mat-icon>
                        Benutzerkonto
                    </button>

                    <button
                        mat-menu-item
                        *ngIf="userRoles && userRoles['einstellungen - benutzerkonto']"
                        routerLink="/settings/affiliate/general"
                    >
                        <mat-icon fontIcon="fa-share-alt"></mat-icon>
                        Partnerprogramm
                    </button>

                    <button mat-menu-item (click)="navigate('/de/Membership/Logout')">
                        <mat-icon fontIcon="fa-sign-out"></mat-icon>
                        Abmelden
                    </button>
                </mat-menu>
                <button
                    mat-icon-button
                    type="button"
                    aria-label="Hilfe aufrufen"
                    *ngIf="!(isHandset$ | async)"
                    [matMenuTriggerFor]="helpMenu"
                >
                    <mat-icon fontIcon="fa-question-circle"></mat-icon>
                </button>
                <mat-menu #helpMenu="matMenu">
                    <button mat-menu-item (click)="openHelpScoutAnswers()">
                        {{ "Hilfe & Support" | translate }}
                    </button>
                    <button mat-menu-item id="open-guuru-chat">
                        {{ "Chat" | translate }}
                    </button>
                    <button mat-menu-item (click)="openChangelog()">
                        {{ "Produktupdates" | translate }}
                    </button>
                </mat-menu>

                <bb-nav-notifications [hidden]="isHandset$ | async"></bb-nav-notifications>
            </mat-toolbar-row>
        </mat-toolbar>
        <bb-nav-status-item *ngIf="(showNavigation | async) && !(isHandset$ | async)"></bb-nav-status-item>
    </div>
    <mat-drawer-container autosize fxFlex>
        <mat-drawer
            [opened]="expandedSidebar || (isHandset$ | async) === false"
            [mode]="(isHandset$ | async) ? 'over' : 'side'"
            #drawer
            fxLayout="row"
            [disableClose]="!(isHandset$ | async)"
            [hidden]="!(showNavigation | async)"
        >
            <div class="left-nav">
                @if (isHandset$ | async) {
                    <div
                        (click)="navigate('/') && !drawer.disableClose && drawer.close()"
                        routerLinkActive="active"
                        [routerLinkActiveOptions]="{ exact: true }"
                    >
                        <mat-icon fontIcon="fa-tachometer-alt"></mat-icon>
                        <span>{{ "label.dashboard" | translate }}</span>
                    </div>
                }
                @for (item of menu; track item) {
                    <div
                        (click)="
                            navigate(item.link) && !drawer.disableClose && item.children.length === 0 && drawer.close()
                        "
                        [title]="expandedSidebar ? null : item.tooltip"
                        class="parent-menu"
                        [ngClass]="{ active: item.link && activeLink(item), 'child-active': childActive(item) }"
                        routerLinkActive="active"
                        [routerLinkActiveOptions]="{ exact: true }"
                    >
                        <div class="icon-wrapper">
                            <mat-icon [fontIcon]="item.icon"></mat-icon>
                        </div>
                        @if (expandedSidebar || (isHandset$ | async)) {
                            <span>{{ item.text | translate }}</span>
                        }
                    </div>
                    <div>
                        @if (item.children && item.children.length > 0) {
                            <div class="nav-child-menu" [ngClass]="'child-count-' + item.children.length">
                                @for (childItem of item.children; track childItem) {
                                    <div
                                        (click)="navigate(childItem.link) && !drawer.disableClose && drawer.close()"
                                        [title]="expandedSidebar ? null : childItem.tooltip"
                                        [ngClass]="{ active: childItem.link && activeLink(childItem) }"
                                        routerLinkActive="active"
                                    >
                                        <div class="icon-wrapper">
                                            <mat-icon [fontIcon]="childItem.icon" [fontSet]="childItem.iconSet"></mat-icon>
                                        </div>
                                        @if (expandedSidebar || (isHandset$ | async)) {
                                            <span>{{ childItem.text | translate }}</span>
                                        }
                                    </div>
                                }
                            </div>
                        }
                    </div>
                }
                <span class="spacing"></span>

                @if (showSettingsMenu) {
                    <div
                        [title]="expandedSidebar ? null : 'Einstellungen'"
                        (click)="navigate('/settings') && !drawer.disableClose && drawer.close()"
                        [ngClass]="settingsActive ? 'active' : null"
                        class="parent-menu"
                    >
                        <div class="icon-wrapper"><mat-icon fontIcon="fa-cogs"></mat-icon></div>
                        @if (expandedSidebar || (isHandset$ | async)) {
                            <span>{{ "title.settings" | translate }}</span>
                        }
                    </div>
                }

                @if ((isHandset$ | async) === false) {
                    <div (click)="toggleSidebar()" class="parent-menu">
                        <div class="icon-wrapper">
                            <mat-icon
                                [fontIcon]="expandedSidebar ? 'fa-angle-double-left' : 'fa-angle-double-right'"
                            ></mat-icon>
                        </div>
                    </div>
                }
                @if ((isHandset$ | async) === true) {
                    <div (click)="navigate('/de/Membership/Logout')" class="parent-menu">
                        <div class="icon-wrapper"><mat-icon fontIcon="fa-sign-out"></mat-icon></div>
                        <span>{{ "label.log_out" | translate }}</span>
                    </div>
                }
            </div>
        </mat-drawer>
        <mat-drawer-content style="position: relative">
            <main fxLayout="column">
                <mat-toolbar
                    fxFlex="none"
                    [hidden]="!(showNavigation | async)"
                    *ngIf="!(isHandset$ | async) && settingsRoute && settingsRoute != ''"
                >
                    <mat-toolbar-row fxLayout="row">
                        <span fxFlex></span>
                        <button
                            mat-icon-button
                            [matTooltip]="'Öffnet die Einstellungen für die aktuelle Ansicht' | translate"
                            [routerLink]="settingsRoute"
                        >
                            <mat-icon fontIcon="fa-cog"></mat-icon>
                        </button>
                    </mat-toolbar-row>
                </mat-toolbar>
                <div [ngClass]="{ content: (showNavigation | async) && contentPadding }" fxFlex>
                    <ng-content></ng-content>
                </div>
            </main>
        </mat-drawer-content>
    </mat-drawer-container>
</div>
